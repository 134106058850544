<template>
  <div class="absolute top-0 w-screen h-screen flex items-center justify-center z-10">
    <div
        class="tunes-container flex flex-col items-center mx-4 py-4 lg:py-10 gap-5 lg:gap-10 2xl:gap-24 w-full sm:w-2/5 overflow-x-hidden overflow-y-auto"
        style="height: 70vh">
      <div v-for="(tune, i) in tunes" :key="i" class="w-full">
        <div class="relative w-full" style="height: 40vh"
             v-if="tune.image === ''">
          <iframe :src="tune.url" class="w-full h-full" allowtransparency="true" allow="encrypted-media"
                  allowfullscreen></iframe>
        </div>

        <div class="flip-card relative w-full" style="height: 40vh" :class="{'is-flipped': tune.isFlipped }" v-else>
          <div class="card-front absolute top-0 left-0 w-full h-full" @click="tune.isFlipped = true">
            <div class="relative cursor-pointer w-full h-full">
              <h2 class="absolute bottom-0 w-full uppercase text-xs lg:text-base tracking-wide text-white bg-black bg-opacity-60 p-2">
                {{ tune.title }}</h2>
              <div class="w-full h-full bg-cover bg-center" :style="{backgroundImage: 'url(' + tune.image + ')'}"/>
            </div>
          </div>
          <div class="card-back absolute top-0 left-0 w-full h-full" v-if="tune.url !== ''">
            <iframe :src="tune.url" class="w-full h-full" allowtransparency="true" allow="encrypted-media"
                    allowfullscreen></iframe>
            <button @click="tune.isFlipped = false" class="close-tune z-20">
              <svg class="w-5 h-5 fill-current" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"/>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {tunes} from '@/tunes'

export default {
  name: 'Tunes',

  data() {
    return {
      tunes: tunes
    }
  },

  methods: {}
}
</script>

<style scoped>

.tunes-container {
  perspective: 1000px;

  > div {
    margin: auto;
  }

  .flip-card {
    transition: transform 800ms ease-out;
    transform-style: preserve-3d;

    &:not(.is-flipped):hover {
      -webkit-animation: wiggle 500ms ease-in-out; /* Safari 4+ */
      -moz-animation: wiggle 500ms ease-in-out; /* Fx 5+ */
      -o-animation: wiggle 500ms ease-in-out; /* Opera 12+ */
      animation: wiggle 500ms ease-in-out;
      animation-delay: 100ms;
    }

    > div {
      backface-visibility: hidden;
    }

    .card-back {
      transform: rotateY(180deg);

      .close-tune {
        @apply absolute z-40 p-2 bg-black bg-opacity-100 text-white cursor-pointer leading-none;
        top: 0;
        right: 0;

        &:focus {
          @apply outline-none;
        }
      }
    }

    &.is-flipped {
      transform: rotateY(180deg);

      .card-back {
        z-index: 40;
      }

      .card-front {
        z-index: 10;
      }
    }

    &:not(.is-flipped) {
      .card-back {
        z-index: 10;
      }

      .card-front {
        z-index: 40;
      }
    }
  }
}

@media (max-width: 1024px) and (orientation: landscape) {
  .h-56 {
    @apply h-40;
  }
}

@keyframes wiggle {
  0% {
    transform: rotateY(0);
  }
  30% {
    transform: rotateY(-5deg);
  }
  50% {
    transform: rotateY(0deg);
  }
  70% {
    transform: rotateY(3deg);
  }
  100% {
    transform: rotateY(0);
  }
}
</style>
