let tunes = [
	{
		title: "MDMelo (feat. Francis) - In the moment EP",
		image: "/img/album.png",
		url: 'https://open.spotify.com/embed/playlist/0nzkYX3Ha5SEpAaKoRaWkt'
	},
	{
		title: "MDMelo (Feat Francis & Miss B.) - BREAK FREE",
		image: "",
		url: 'https://www.youtube.com/embed/videoseries?list=PLHpXWASutEYhkc8CfFT-_BpFlWgIclxaL'
	},
	{
		title: "MDMelo (Feat Francis & Miss B.) - BREAK FREE",
		image: "",
		url: 'https://www.youtube.com/embed/BSIpPu-qMAc',
		isHighlighted: true
	},
	{
		title: "MDMelo (Feat Francis & Miss B.) - BREAK FREE",
		image: "/img/album.png",
		url: 'https://open.spotify.com/embed/track/35C98wbvyi8VGCbvsfJ2S0',
		isHighlighted: true
	}
]

export {tunes}
